
import { useAuthStore } from "~/stores/auth";

export default defineNuxtPlugin(async (nuxtApp) => {
    const store = useAuthStore();

    if (store.user)
        useInsider().user(store.user);

    setTimeout(() => {
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://dominopizzarunew.api.useinsider.com/ins.js?id=10009288");
        document.body.appendChild(scriptEle);
    }, 2000);
});